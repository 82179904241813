import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import React, {useMemo} from 'react'
import {KenticoCityPageData} from '~/kentico/components/city-page'
import {KenticoGlobalScriptData} from '~/kentico/components/global-script'
import {KenticoPage} from '~/kentico/components/page'
import {KenticoPageLinkData} from '~/kentico/components/page-link'
import {KenticoStoreData, toStore} from '~/kentico/components/store'
import {LinkedItems, createUrl, linkedItems} from '~/kentico/util'
import {StoresProvider} from '~/stores/components/provider'
import {Store} from '~/stores/types'
import {CookieError} from '~/common/components/cookie-error'
import {GatsbyLayout} from '../components/layout'
import {useDecode} from '../util'

const Props = IO.type({
  data: IO.type({
    globalScripts: KenticoGlobalScriptData,
    page: KenticoCityPageData,
    pages: LinkedItems(
      IO.intersection([
        KenticoPageLinkData,
        IO.type({
          elements: IO.type({
            store: LinkedItems(
              IO.type({
                id: IO.string,
              }),
            ),
          }),
        }),
      ]),
    ),
    stores: LinkedItems(KenticoStoreData),
  }),
  pageContext: IO.type({
    storeIds: IO.array(IO.string),
  }),
})

/** Query from template. */
export const query = graphql`
  query($id: String!, $storeIds: [String!]!, $pageIds: [String!]!) {
    page: kontentItemCityPage(id: {eq: $id}) {
      ...KenticoCityPage
    }
    stores: allKontentItemStore(filter: {id: {in: $storeIds}}) {
      nodes {
        ...KenticoStore
      }
    }
    pages: allKontentItemStorePage(filter: {id: {in: $pageIds}}) {
      nodes {
        ...KenticoPageLink
        elements {
          store {
            nodes: value {
              id
            }
          }
        }
      }
    }
    ...KenticoGlobalScripts
  }
`

const CityPage = (props: unknown) => {
  const {
    data: {pages, stores, ...data},
    pageContext: {storeIds},
  } = useDecode(Props, props)

  // Ensure sorting matches original data
  const sortedStores = useMemo(
    () =>
      [...linkedItems(stores)]
        .sort((a, b) => storeIds.indexOf(a.id) - storeIds.indexOf(b.id))
        .map<Store>(node => {
          const store = toStore(node)
          const page = linkedItems(pages).find(p =>
            linkedItems(p.elements.store).some(s => s.id === node.id),
          )
          return page ? {...store, url: createUrl(page)} : store
        }),
    [pages, stores, storeIds],
  )

  return (
    <GatsbyLayout contentId={data.page.system.id}>
      <CookieError />
      <StoresProvider stores={sortedStores}>
        <KenticoPage {...data} />
      </StoresProvider>
    </GatsbyLayout>
  )
}

/** City page. */
// eslint-disable-next-line import/no-default-export
export default CityPage
