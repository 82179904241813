import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoBodyItemData} from '../body-item'
import {KenticoPageLinkData} from '../page-link'
import {KenticoPageScriptData} from '../page-script'
import {KenticoSchemaData} from '../schema'

/** City page. */
export const KenticoCityPageData = IO.intersection(
  [
    KenticoPageLinkData,
    IO.type({
      elements: IO.type({
        body: LinkedItems(KenticoBodyItemData),
        canonicalPage: LinkedItems(KenticoPageLinkData),
        description: IO.type({
          value: IO.string,
        }),
        robots: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.string,
            }),
          ),
        }),
        schema: LinkedItems(KenticoSchemaData),
        scripts: LinkedItems(KenticoPageScriptData),
        title: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        id: IO.string,
      }),
    }),
  ],
  'KenticoCityPage',
)

/** Data type. */
export interface KenticoCityPageData
  extends IO.TypeOf<typeof KenticoCityPageData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoCityPage on Node {
    ... on kontent_item_city_page {
      ...KenticoPageLink
      system {
        id
      }
      elements {
        body: body__body {
          nodes: value {
            ...KenticoBodyItem
          }
        }
        canonicalPage: metadata__canonical_page {
          nodes: value {
            ...KenticoPageLink
          }
        }
        description: metadata__description {
          value
        }
        robots: metadata__robots {
          value {
            codename
          }
        }
        schema: metadata__schema {
          nodes: value {
            ...KenticoSchema
          }
        }
        scripts: metadata__scripts {
          nodes: value {
            ...KenticoPageScript
          }
        }
        title: metadata__title {
          value
        }
      }
    }
  }
`
